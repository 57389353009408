.Mapbox {
  .boxdraw {
    background: rgba(56, 135, 190, 0.1);
    border: 2px solid #3887be;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-ruler svg {
    fill: #a2aaad !important;
    width: 18px !important;
    top: 1px;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-ruler button {
    border-radius: 4px;

    &:hover {
      background-color: transparent;
    }
    &:hover svg {
      fill: black !important;
    }

    &.-active svg {
      fill: white !important;
    }

    &.-active {
      background-color: var(--color-primary) !important;
    }

    &.-active:hover svg {
      fill: black !important;
    }
  }

  .mapboxgl-ctrl-top-right {
    top: 45px;
    .mapboxgl-ctrl-group {
      border-radius: 0;
      box-shadow: none;
    }
    .mapboxgl-ctrl-group:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .mapboxgl-ctrl-group:nth-child(2) {
      margin-top: 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-ruler {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 2px 2px 0px 2px;
  }

  .navigate-control {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 2px 2px 2px 2px;
    max-width: 34px;
    position: absolute;
    right: 0;
    margin: 10px 10px 0 0;
    float: right;
    border-radius: 4px;
    background: #fff;
    z-index: 1;

    .icon-toggle {
      color: #a2aaad;
      &:hover {
        color: var(--color-primary);
      }
      &.isActive {
        color: var(--color-primary);
      }
    }
  }
  .zoom-control {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    margin: 10px 10px 0 0;
    float: right;
    border-radius: 4px;
    background: #fff;
    .icon-toggle {
      color: #a2aaad;
      &:hover {
        color: var(--color-primary);
      }
    }
    .BaseDropdown {
      height: 100%;
      width: 95px;
      justify-content: center;
      padding: var(--space-1);
      font-size: 1.2rem;
      color: #a2aaad;
      border: none;
      &:hover {
        color: var(--color-text);
      }
      .selected-option {
        margin-right: 0;
      }
      & > div:last-child {
        display: none;
      }
    }
  }
}
.BaseMenu {
  .navigate-options {
    position: relative;
    width: 420px;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
  .navigate-tabs {
    display: flex;
    justify-content: space-around;
  }
  .navigate-content {
    padding: 15px 25px 25px;
    background: var(--color-surface-01);
    .input,
    .BaseDropdown {
      height: 40px;
    }
    .BaseDropdown {
      padding: 0 8px;
    }
    .BaseDropdown .selected-option {
      margin-right: 8px;
    }
  }
}
$legend-height: 200px;
$legend-width: 250px;
.mapboxgl-control-container {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.ipdb-options {
  width: 400px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  .ipdb-option-item:first-child {
    .trigger-container {
      width: 100%;
      svg {
        flex-shrink: 0;
      }
    }
  }
  .ipdb-option-item {
    padding: 12px 20px;
    label {
      font-weight: var(--fontWeightMedium);
      color: #9b9b9b;
    }
    label.active {
      color: var(--color-text);
      font-weight: var(--fontWeightBold);
    }
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .removable-property {
      height: 32px;
      background-color: #d9e1e266;
      padding: 5px 8px;
      border-radius: 4px;
      min-width: 140px;
      position: relative;
      justify-content: space-between;
      .icon-toggle {
        svg {
          color: #b5b5b5;
          transition: color var(--duration);
        }
        &:hover {
          svg {
            color: var(--color-danger);
          }
        }
      }
    }
    &.alt-title .labeled-switch_label {
      color: #9b9b9b;
      font-weight: var(--fontWeightMedium);
    }
  }

  .ipdb-option-item.bubble-container {
    background: #fbfcfc;
    label {
      color: var(--color-text);
    }
    .button {
      font-size: 1.4rem;
      width: 120px;
      border-radius: 4px;
    }
  }

  .ipdb-option-item.scale-container {
    background: #fbfcfc;
    span {
      font-weight: var(--fontWeightMedium);
      color: var(--color-text);
    }
    .BaseDropdown {
      width: 160px;
      height: 40px;
    }
    .input {
      width: 100px;
      height: 40px;
    }
  }

  .legend-container {
    position: absolute;
    right: 5px;
    bottom: 25px;
    width: $legend-width;
    z-index: 10;
    background-color: var(--color-white);
    height: $legend-height;
    border: 1px solid #eeeeee;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    .map-legend {
      .legend {
        max-height: $legend-height;
      }
    }
    .legend-list {
      height: $legend-height - 28px;
      .legend-item {
        .legend-item-title {
          width: $legend-width - 50px;
          max-width: $legend-width - 50px;
        }
      }
    }
  }
}

.new-well-options {
  min-width: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .input-field {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  label {
    min-width: 140px;
    display: inline-block;
  }
  .title {
    font-weight: bold;
    padding-bottom: 10px;
  }
  .buttons {
    padding-top: 10px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}

.Mapbox.show-grid {
  .MapToolbar {
    top: 38px !important;
    left: 40px !important;
  }

  .mapboxgl-ctrl-top-right {
    top: 69px;
  }
  .zoom-control {
    top: 28px;
  }

  .navigate-control {
    top: 195px;
  }
}
.Mapbox {
  background-color: var(--color-background);
  box-shadow: 0 0 2px rgba(var(--color-primary-rgb), 0.16);
  overflow: hidden;

  .MapToolbar {
    top: 1rem;
    left: 1rem;
  }
}
.separator {
  background-color: #d9e1e2;
  width: 100%;
  height: 1px;
}

.cr.color input {
  min-height: 25px;
}
