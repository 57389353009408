/* checkbox */
.tree-wrapper {
  .ant-tree {
    .ant-tree-checkbox-inner {
      width: 18px;
      height: 18px;
    }
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
      border-color: var(--color-primary);
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
      background-color: var(--color-primary);
      width: 6px;
      height: 6px;
    }
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: inherit;
    }

    .ant-tree-draggable-icon {
      display: none !important;
    }

    .ant-tree-node-content-wrapper {
      padding: 0;
    }
    .ant-tree-node-content-wrapper label {
      margin: 0;
    }
    .ant-tree-indent-unit {
      width: 19px;
    }
    .ant-tree-treenode {
      padding: 0;
      margin-bottom: 4px;
    }
  }
}

.arps-table {
  .ant-table {
    .ant-table-thead > tr > th {
      background: #fff !important;
      padding: 10px 6px 0 6px;
      text-align: left;
    }
    .ant-table-tbody > tr > td {
      background: #fff;
      padding: 5px 6px;
    }
  }
}
.export-popover {
  padding: 0;
  .ant-popover-inner-content {
    padding: 0;
  }
}
.title-has-error .input {
  border: 1px solid red !important;
  box-shadow: inset 0 0 0 1px red !important;
}

.forecast-node .ant-popover-inner-content {
  padding: 0;
}

.project-forecasts_start-date-toggle {
  padding-left: 2px;
}

.project-forecasts_typewell-normalize-toggle {
  margin-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

@mixin typewellNormalizeToggle($isWidget) {
  margin-top: 8px;
  padding-left: 12px;
  padding-right: 12px;

  @if $isWidget {
    margin-top: 0px;
  }
}

.project-forecasts_typewell-normalize-toggle {
  @include typewellNormalizeToggle(false);
}

.project-forecasts_typewell-normalize-toggle-widget {
  @include typewellNormalizeToggle(true);
}
