.MapToolbar {
  display: flex;
  background-color: var(--color-surface-01);
  box-shadow: 0 2px 8px var(--fume);
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: var(--border-radius);
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
    align-items: center;

    &:hover .dropdown-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }

  .item {
    color: #a2aaad;
    background-color: var(--color-surface-01);
    transition: color var(--duration);
    &.isActive,
    &:hover {
      z-index: 1;
      color: var(--color-primary);
    }
  }
  .icon-toggle {
    color: #a2aaad;
    transition: color var(--duration);
    &.isActive,
    &:hover {
      color: var(--color-primary);
    }
  }
  .lasso-toggle::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 7px solid;
    border-left: 6px solid white;
    width: 0;
  }

  .BaseIconToggle {
    padding: 1rem;
  }
}
